import React from "react";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  // Determine the range of pages to display around the current page
  const siblingCount = 1; // Number of pages to display before and after the current page
  const firstPageIndex = 1;
  const lastPageIndex = totalPages;

  let startPage = Math.max(firstPageIndex, currentPage - siblingCount);
  let endPage = Math.min(lastPageIndex, currentPage + siblingCount);

  // Adjust the start and end page if near the beginning or end
  if (currentPage <= 3) {
    startPage = 1;
    endPage = Math.min(firstPageIndex + 2 * siblingCount, lastPageIndex);
  }

  if (currentPage > totalPages - 3) {
    startPage = Math.max(lastPageIndex - 2 * siblingCount, firstPageIndex);
    endPage = lastPageIndex;
  }

  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  return (
    <nav aria-label="Page navigation" className="mt-3">
      <ul className="pagination justify-content-center">
        <li
          className={`page-item ${
            currentPage === firstPageIndex ? "disabled" : ""
          }`}
        >
          <button
            className="page-link me-2"
            onClick={() => onPageChange(currentPage - 1)}
          >
            Previous
          </button>
        </li>

        {currentPage > 2 && (
          <li className="page-item">
            <button className="page-link" onClick={() => onPageChange(1)}>
              1
            </button>
          </li>
        )}

        {startPage > 2 && (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        )}

        {pageNumbers.map((page) => (
          <li
            key={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}

        {currentPage < totalPages - 1 && endPage < totalPages - 1 && (
          <li className="page-item">
            <span className="page-link">...</span>
          </li>
        )}

        {currentPage < totalPages - 1 && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => onPageChange(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        )}

        <li
          className={`page-item ${
            currentPage === lastPageIndex ? "disabled" : ""
          }`}
        >
          <button
            className="page-link ms-1"
            onClick={() => onPageChange(currentPage + 1)}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
