const Cookies = require("js-cookie");
var hostname = window.location.href;

module.exports = {
  server: hostname.includes("localhost")
    ? "http://localhost:7400"
    : "https://api.cheapship.info",

  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  },

  downloadConfig: {
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  },

  // recaptcha keys
  RECAPTCHA_KEY: "6Ld981gpAAAAAA3PLFEOaiaD6BESyXTbBbYA_i1K",

  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("token");
        window.location.href = "/";
      }
    }
    return true;
  },
};
