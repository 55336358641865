import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const Dashboard = (props) => {
  const [orders, setOrders] = useState([]);
  const [OrderStats, setOrderStats] = useState({});
  const [revenueStats, setRevenueStats] = useState({});
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState({});
  const [loader, setLoader] = useState("");
  const [search, setSearch] = useState("");

  const getOrders = async () => {
    await axios
      .get(
        `${server}/api/v1/order/read?search=${search}&status=${filter}&sort=${sort}&page=${page}&limit=${limit}`,
        config
      )
      .then((res) => {
        setOrders(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadPdf = async (id, tracking) => {
    await axios
      .get(`${server}/api/v1/order/download/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${tracking}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getOrders();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sort, page, search]);

  const duplicateOrder = async (id) => {
    setLoader(<Loader />);

    await axios
      .post(server + "/api/v1/order/duplicate/" + id, {}, config)
      .then((res) => {
        toast.success(res.data.message);
        setLoader("");
        getOrders();
        document.getElementById("close").click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
      });
  };

  const getOrdersStat = async () => {
    await axios
      .get(server + "/api/v1/dashboard/orders-stats", config)
      .then((res) => {
        setOrderStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRevenueStats = async () => {
    await axios
      .get(server + "/api/v1/dashboard/user-revenue-stats", config)
      .then((res) => {
        setRevenueStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrdersStat();
    getRevenueStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Dashboard</h3>
                  </div>
                </div>
              </div>
              {/* .nk-block-head */}
              <div className="nk-block">
                <div className="row g-gs ">
                  <div className="col-md-6 col-sm-12">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title text-center">Orders</h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Today</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.todayOrdersCount
                                ? OrderStats.todayOrdersCount
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Week</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.thisWeekOrdersCount
                                ? OrderStats.thisWeekOrdersCount
                                : 0}
                            </div>
                          </div>{" "}
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Month</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.thisMonthOrdersCount
                                ? OrderStats.thisMonthOrdersCount
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">AllTime</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </div>
                          </div>
                          {/* <div className="data">
                            <div className="data-head">
                              <h6 className="overline-title">Custom</h6>
                            </div>
                            <div className="amount">
                              {OrderStats.allTimeOrdersCount
                                ? OrderStats.allTimeOrdersCount
                                : 0}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="card">
                      <div className="nk-ecwg nk-ecwg6">
                        <div className="card-inner">
                          <div className="card-title-group">
                            <div className="card-title">
                              <h5 className="title text-center">
                                Amount Spent
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Today</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.todayRevenue
                                ? revenueStats.todayRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Week</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.thisWeekRevenue
                                ? revenueStats.thisWeekRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>{" "}
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">Month</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.thisMonthRevenue
                                ? revenueStats.thisMonthRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>
                          <div className="data border-bottom">
                            {/* heading */}
                            <div className="data-head">
                              <h6 className="overline-title">AllTime</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.allTimeRevenue
                                ? revenueStats.allTimeRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div>
                          {/* <div className="data">
                            <div className="data-head">
                              <h6 className="overline-title">Custom</h6>
                            </div>
                            <div className="amount">
                              $
                              {revenueStats.allTimeRevenue
                                ? revenueStats.allTimeRevenue.toFixed(2)
                                : 0}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nk-block nk-block-lg mt-5">
                  <div className=" d-flex justify-content-start">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Order History</h4>
                    </div>
                  </div>
                  <span className="text-muted">{total} orders</span>
                  <div className="card-filter mt-3">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex justify-content-center ">
                        <div className="form-group">
                          <select
                            name="sort"
                            id=""
                            className="form-control"
                            value={sort}
                            onChange={(e) => {
                              setSort(e.target.value);
                            }}
                          >
                            <option value="asc">Sort by Date Ascending</option>
                            <option value="des">Sort by Date Descending</option>
                          </select>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center ">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-preview mt-2 mb-3">
                    <div className="table-responsive">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Tracking</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{order.fromName}</td>
                              <td>{order.toName}</td>
                              <td>{order.labelType?.name}</td>
                              <td>${order.price}</td>
                              <td>{order.tracking}</td>
                              <td>
                                {order.isRefunded ? (
                                  <span className="badge badge-danger">
                                    Refunded
                                  </span>
                                ) : (
                                  <span className="badge badge-success">
                                    Paid
                                  </span>
                                )}
                              </td>
                              <td>
                                {new Date(order.createdAt).toLocaleString()}
                              </td>
                              <td>
                                {/* duplicate order */}
                                <Link
                                  to=""
                                  className="btn btn-sm btn-primary mr-2 px-2 py-1"
                                  data-toggle="modal"
                                  data-target="#duplicate"
                                  onClick={(e) => {
                                    setOrder(order);
                                  }}
                                >
                                  <em className="icon ni ni-copy" />
                                </Link>

                                {!order.isRefunded && (
                                  <Link
                                    to=""
                                    className="btn btn-sm btn-info mr-2 px-2 py-1"
                                    onClick={(e) => {
                                      downloadPdf(order._id, order.tracking);
                                    }}
                                  >
                                    <em className="icon ni ni-download" />
                                  </Link>
                                )}
                                {/* tracking */}

                                <a
                                  href={`https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${order.tracking}%2C&tABt=true`}
                                  className="btn btn-sm btn-secondary me-2 px-2 py-1"
                                >
                                  <em className="icon ni ni-eye mr-1" />
                                  Track Package
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <nav>
                      <ul className="pagination">
                        <li
                          className={
                            page === 1 ? "page-item disabled" : "page-item"
                          }
                        >
                          <Link
                            className="page-link"
                            to=""
                            onClick={(e) => {
                              setPage(page - 1);
                            }}
                          >
                            Prev
                          </Link>
                        </li>

                        <li className="page-item active" aria-current="page">
                          <Link className="page-link" to="">
                            {page} <span className="sr-only">(current)</span>
                          </Link>
                        </li>

                        <li
                          className={
                            page === totalPages
                              ? "page-item disabled"
                              : "page-item"
                          }
                        >
                          <Link
                            className="page-link"
                            to=""
                            tabIndex={-1}
                            aria-disabled={page === totalPages ? true : false}
                            onClick={(e) => {
                              setPage(page + 1);
                            }}
                          >
                            Next
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      <div
        id="duplicate"
        className="modal fade"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Duplicate Order
              </h5>
              <Link
                to="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close"
              >
                <em className="icon ni ni-cross" />
              </Link>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you would like to order a duplicate of this label
                order? you will be charged ${order.price} again .
              </p>

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    duplicateOrder(order._id);
                  }}
                >
                  Yes {loader}
                </button>
                <button
                  className="btn btn-secondary btn-sm ml-2"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
